<script lang="ts">
	import LinkCard from "@components/LinkCard.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import type { Snippet } from "svelte";
	import arrowNext from "@core/assets/icons/arrow-next.svg?raw";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import Card from "./Card.svelte";
	import type { CardProps } from "./CardProps.js";
	import type { CtaButtonProp } from "./CtaButtonProp.js";
	import type { LinkCardProps } from "./LinkCardProps.js";
	import type { ServiceCardItem } from "./ServiceCardItem.js";

	const {
		headline,
		desc,
		cards,
		ctaButton,
	}: {
		headline?: Snippet;
		desc?: Snippet;
		cards: LinkCardProps[] | CardProps[] | ServiceCardItem[];
		ctaButton?: CtaButtonProp;
	} = $props();
	const { pagePath, tenant } = getGlobalTemplateProps();
	const currentPath = pagePath[tenant.locale];
	const doesCardsHaveHref = cards.some((card) => "href" in card);
</script>

<div class="grid-container grow max-lg:full-width">
	<section>
		{#if headline}
			<div class="flex flex-col gap-8 mb-4 md:mb-14 max-w-4xl mx-auto">
				<h2 class={["md:text-center"]}>
					{@render headline()}
				</h2>
				{@render desc?.()}
			</div>
		{/if}
		<div>
			<div
				class={[
					"max-w-[75rem] grid justify-items-center sm:justify-items-stretch lg:grid-cols-3 gap-1 sm:gap-[1.03rem] mb-8 lg:mb-12 mx-auto",
					doesCardsHaveHref ? "grid-cols-1 min-[370px]:grid-cols-2" : "grid-cols-1 sm:grid-cols-2",
				]}
			>
				{#each cards as card (card)}
					{#if card.show}
						{#if "href" in card && card.href !== undefined && card.href !== currentPath}
							<LinkCard className="w-full" title={card.title} iconName={card.iconName} href={card.href}>
								{#snippet desc()}
									{card.desc}
								{/snippet}
							</LinkCard>
						{:else}
							<Card className="w-full" title={card.title} iconName={card.iconName}>
								{#snippet desc()}
									{card.desc}
								{/snippet}
							</Card>
						{/if}
					{/if}
				{/each}
			</div>
			<Link
				variant={ctaButton?.buttonVariant ?? ButtonVariant.Phlox}
				class={["mx-auto flex items-center justify-center mb-2", ctaButton?.class]}
				href={ctaButton?.href ?? getConnectWithFastestPath(tenant)}
				>{ctaButton?.text ?? "Chci fastest služby"}
				<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
			</Link>
		</div>
	</section>
</div>
